@import '../../../styles/common/variables.scss';

.e-pager {

  margin-top: 5% !important;
}

.e-grid {
  border-radius: 6px !important;
  padding: 6px !important;
  box-shadow: $boxshadow-light !important;
  border: solid .5px rgb(218, 219, 320);
}

.e-grid .e-rowcell.customcss {
  cursor: no-drop !important;
}

.e-grid .e-headercell.customcss {
  cursor: no-drop !important;
}

.extinguida {

  background-color: rgba(241, 244, 251, 0.9);
  color: rgb(114, 112, 112);

  td {
    color: red
  }
}

.e-tooltip-wrap {
  background-color: #409cce !important;
  color: black !important;
  border-radius: 2px !important;
}

.e-tooltip-wrap .e-arrow-tip-inner.e-tip-bottom {
  color: #409cce;

}

.e-checkbox-wrapper .e-frame.e-check {
  color: #409cce;
}

.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check,
.e-css.e-checkbox-wrapper.e-checkbox-disabled .e-frame.e-check {
  background-color: #409cce;
  border: 1px solid #409cce;
}

.file-icon {
  display: inline-block;
  margin: auto;
  width: 14px;
  height: 16px;
  background-size: contain;
  background-image: url("../../../img/icons/svg/communications/icon-attachment.svg");
  background-repeat: no-repeat;
  background-position: center center;
  color: $accent;
}
.file-icon-blue {
  display: inline-block;
  margin: auto;
  width: 18px;
  height: 18px;
  background-size: contain;
  background-image: url("../../../img/icons/record/adjunto.png");
  background-repeat: no-repeat;
  background-position: center center;
  color: $accent;
}
.information-icon-blue {
  display: inline-block;
  margin: auto;
  width: 18px;
  height: 18px;
  background-size: contain;
  background-image: url("../../../img/icons/record/informacion.png");
  background-repeat: no-repeat;
  background-position: center center;
  color: $accent;
  cursor: pointer;
}
.information-icon-orange {
  display: inline-block;
  margin: auto;
  width: 18px;
  height: 18px;
  background-size: contain;
  background-image: url("../../../img/icons/record/informacion-orange.png");
  background-repeat: no-repeat;
  background-position: center center;
  color: $accent;
  cursor: pointer;
}
.big {
  width: 60px;
  height: 60px;
}
.edit-delta-icon {
  display: inline-block;
  margin: auto;
  width: 14px;
  height: 16px;
  background-size: contain;
  background-image: url("../../../img/icons/Delta/editDeltaIcon.png");
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
}

.check-in-delta-icon {
  display: inline-block;
  margin: auto;
  width: 14px;
  height: 16px;
  background-size: contain;
  background-image: url("../../../img/icons/Delta/garrapata.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.check-out-delta-icon {
  display: inline-block;
  margin: auto;
  width: 14px;
  height: 16px;
  background-size: contain;
  background-image: url("../../../img/icons/Delta/boton-eliminar.png");
  background-repeat: no-repeat;
  background-position: center center;
}

.remesado-delta-icon {
  display: inline-block;
  margin: auto;
  width: 14px;
  height: 16px;
  background-size: contain;
  background-image: url("../../../img/icons/Delta/cuota.png");
  background-repeat: no-repeat;
  background-position: center center;
  
}
@keyframes scaleUp {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
.icon-check-blue {
  display: inline-block;
  margin: auto;
  width: 60px;
  height: 60px;
  background-size: contain;
  background-image: url("../../../img/icons/symbols/check-confirm.png");
  background-repeat: no-repeat;
  background-position: center center;
  animation: scaleUp 1s ease-in-out;
}
.no-remesado-delta-icon {
  display: inline-block;
  margin: auto;
  width: 14px;
  height: 16px;
  background-size: contain;
  background-image: url("../../../img/icons/Delta/descarga-curve-arrow-grey.png");
  background-repeat: no-repeat;
  background-position: center center;
}
.error-icon {
  display: inline-block;
  margin: auto;
  width: 14px;
  height: 16px;
  background-size: contain;
  background-image: url("../../../img/icons/symbols/sin-datos.png");
  background-repeat: no-repeat;
  background-position: center center;
}
.error-icon2{
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  width: 16px;
  height: 16px;
  color: red;
  border-radius: 100%;
  padding: 9px;
  border: solid 1px red;
  font-weight: bold;
  animation: scaleUp 1s ease-in-out;

}

.container-btn-remesar {
  display: flex;
  justify-content: end;
  padding: 20px;

}

.btn-remesar {
  color: aliceblue;
  background-color: #409cce;
}

.title-epidode-reha {
  margin-top: 8px;
  margin-left: 18px;
  margin-bottom: 8px;
  color:  #409cce;
}

.episode-rehab {
  border: 1px solid #409cce;
  margin-top: 20px;
  margin-bottom: 20px;
  padding: 8px;
  border-radius: 10px;
  background-color:#eeeeee;
}


.progress-bar-container2 {
  width: 100%;
  height: 3px;
  background-color: #e0e0e0;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 5px;
}

.progress-bar2 {
  height: 100%;
  background-color: $accent;
  width: 0%;
  transition: width 0.3s ease;
}

@import '../../../styles/common/variables.scss';
.form-modal {
  .form-title {
    margin-bottom: 24px;
    font-size: 28px;
    font-weight: 400;
    color: #409CCC;     
    text-align: center;
  }
  .form-error {
    font-size: 12px;
    font-weight: 400;
    color: red;
  }
}

.form-regular {
  .input {
    width: 100%;
    transition: none;
    transition: border .2s ease-in-out;
    &.invalid {
      border: 1px solid red;
      margin-bottom: 0px;
      &+.invalid {
        margin-top: 16px;
      }
    }
  }
  .container {
    .input {
      min-width: 100%;
      font-size: 10px;
    }
  }
  .form-error+.input {
      margin-top: 8px;
  }
  .form-error {
      min-height: 16px;
      font-size: 12px;
      font-weight: 400;
      color: red
  }
  .btns-container {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end;
    &.center-btns {
      text-align: center;
    }
  }
  &.form-labels {
    .label-block {
      margin-bottom: 39px;
    }
    .input {
      padding-top: 8px;
  
    }
    .form-error {
      position: absolute;
      bottom: -22px;
    }
    .inputs-row-container {
      .label-block {
        width: calc(50% - 8px);
        display: inline-block;
        &+.label-block {
          margin-left: 16px;
        }
      }
    }
  }
}

.auto-complete-component--form {
  width: 100%;
  .e-float-input.e-control-wrapper.e-input-group.e-ddl {
    margin: 0;
  }
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group:not(.e-float-icon-left) .e-float-line::after,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::before,
  .e-float-input.e-control-wrapper.e-input-group.e-float-icon-left .e-input-in-wrap .e-float-line::after {
    background: none;
  }
  .e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
  .e-input-group.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap,
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-warning:not(.e-success):not(.e-error),
  .e-input-group.e-control-wrapper.e-input-focus:not(.e-float-icon-left).e-error:not(.e-success):not(.e-warning),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error),
  .e-float-input.e-control-wrapper.e-input-group.e-input-focus:not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning):not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left).e-success:not(.e-warning):not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success).e-warning:not(.e-error) input,
  .e-float-input.e-control-wrapper.e-input-focus:not(.e-input-group):not(.e-float-icon-left):not(.e-success):not(.e-warning).e-error input,
  .e-input-group.e-control-wrapper.e-float-icon-left.e-input-focus:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    border: none;
  }
  .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left), .e-input-group.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap, .e-float-input.e-control-wrapper.e-float-icon-left:not(.e-success):not(.e-warning):not(.e-error) .e-input-in-wrap {
    border: none;
  }

  .e-input-group input.e-input, .e-float-input.e-input-group input, .e-input-group.e-control-wrapper input.e-input, .e-float-input.e-input-group.e-control-wrapper input, .e-float-input input, .e-float-input.e-control-wrapper input, .e-control.e-autocomplete.e-lib.e-input.e-keyboard:hover {
    padding-left: 16px;
    border: 1px solid $light-gray;
    border-radius: 4px;
    font-size: 16px;
    padding-top: 8px;
    padding-bottom: 0px;
    &:focus {
      border: 1px solid #409CCE;
      box-shadow: 0px 0px 6px 0px rgba(64, 156, 206, 0.4);
      & ~ label.e-float-text.e-label-top {
        color: #409CCE;
      }
    }
  }
  .e-clear-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 8px;
  }
  .e-float-input input:focus ~ label.e-float-text, .e-float-input input:valid ~ label.e-float-text, .e-float-input input ~ label.e-label-top.e-float-text, .e-float-input input[readonly] ~ label.e-label-top.e-float-text, .e-float-input input[disabled] ~ label.e-label-top.e-float-text, .e-float-input input label.e-float-text.e-label-top, .e-float-input.e-control-wrapper input:focus ~ label.e-float-text, .e-float-input.e-control-wrapper input:valid ~ label.e-float-text, .e-float-input.e-control-wrapper input ~ label.e-label-top.e-float-text, .e-float-input.e-control-wrapper input[readonly] ~ label.e-label-top.e-float-text, .e-float-input.e-control-wrapper input[disabled] ~ label.e-label-top.e-float-text, .e-float-input.e-control-wrapper input label.e-float-text.e-label-top, .e-float-input.e-control-wrapper.e-input-focus input ~ label.e-float-text, .e-float-input.e-input-focus input ~ label.e-float-text{
    position: absolute;
    left: 16px;
    top: 0px;
    width: fit-content;
    padding: 0px 6px;
    color: #636363;
    text-transform: uppercase;
    background: white;
    &~.e-float-line {
      display: none;
    }
  }
  label.e-float-text, .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-float-input.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-float-input.e-control-wrapper.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-bigger .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-bigger .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-small .e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-small .e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-float-input.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-float-input.e-control-wrapper.e-small.e-bigger:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-bigger .e-float-input.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom, .e-bigger .e-float-input.e-control-wrapper.e-small:not(.e-error):not(.e-input-focus) input:not(:focus):valid ~ label.e-float-text.e-label-bottom {
    position: absolute;
    left: 16px;
    top: -8px;
    width: fit-content;
    padding: 0px;
    color: #636363;
    background: white;
  }
}

input.e-input::selection,
textarea.e-input::selection,
.e-input-group input.e-input::selection,
.e-input-group.e-control-wrapper input.e-input::selection,
.e-float-input input::selection,
.e-float-input.e-control-wrapper input::selection,
.e-input-group textarea.e-input::selection,
.e-input-group.e-control-wrapper textarea.e-input::selection,
.e-float-input textarea::selection,
.e-float-input.e-control-wrapper textarea::selection {
  background: auto;
  color: #fff;
}
.input-file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; max-width: 124px;
  background-color: #409CCE !important;
  color: aliceblue !important;

}
.input-file-label {
background-color: #409CCE;
color: #fff;
display: inline-block;
 transition: all .5s;
 cursor: pointer;
 padding: 15px 40px !important;
 text-transform: uppercase;
 width: fit-content;
 text-align: center;
}
.btn-communication {
  margin-bottom: 0;
  margin-left: 10px;
  margin-right: 10px;
  &:hover{
    cursor: pointer;
  }
}
.title-selected-files {
  color: #409CCE;
  margin-top: 20px;
}
.li-file-selected {
  padding: 6px 16px;
  // background: #fff;
  border: 1px solid #409CCE;
  width: fit-content;
  border-radius: 3px;
  margin: 6px;
}
.remove-file-btn {
  color: tomato;
  margin-left: 20px;
  width: 18px;
  height: 18px;
  &:hover{
    cursor: pointer;
  }
  
}

.selected-files {
display: flex;
flex-direction: row;
flex-wrap: wrap;
padding: 10px;
color: #409CCE;
}
.btns-container-create-communication {
justify-content: space-between;
}


.custom-datepicker {
  width: 100%;
  position: relative;

}

.custom-datepicker-container::before {
  content: '';
  position: absolute;
  top: 8px; 
  right: 10px;
  width: 20px; 
  height: 20px; 
  background-image: url('../../../img/calendarForm.png'); 
  background-size: cover;
  background-color: aqua;
}
.buttons-form-otp {
 display: flex;
 justify-content: center;
 align-items: center;
 width: 100%;
 
}
.btn-otpep{
  margin-top: 20px;
}